<template>
    <st-filters-pagination
        stateModule="complainType/list"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('COMPLAIN_TYPE.LIST.TITLE')"
        :moreFilters="false"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ComplainTypeModel } from '@/modules/complain-type/complain-type-model';

    const { fields } = ComplainTypeModel;

    const filterSchema = new FilterSchema([
        fields.name,
        fields.status,
    ]);

    export default {
        name: 'ComplainTypeListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'complainType/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'complainType/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>

<style scoped>

</style>
