import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import { DocumentSeriesTypeField } from '@/modules/document-series-type/document-series-type-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';

const label = (name) => i18n.t(`COMPLAIN_TYPE.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`COMPLAIN_TYPE.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`COMPLAIN_TYPE.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }, { placeholder: placeholder('name') }),
    status: new EnumField('status', label('status'), [
        { value: 'active', text: enumLabel('status', 'active') },
        { value: 'inactive', text: enumLabel('status', 'inactive') }
    ], {
        required: true
    }, { placeholder: placeholder('status') }),
    complain_document_series_type_id: DocumentSeriesTypeField.relationToOne(
        'complain_document_series_type_id',
        label('complain_document_series_type'),
        { required: true },
    ),
    tenant_id: OrganizationField.relationToOne(
        'tenant_id',
        label('tenant_id'),
    ),
};

export class ComplainTypeModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
