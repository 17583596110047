<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="isLoading"
            @edit="onEdit"
            @delete="onDelete"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { ComplainTypeModel } from '@/modules/complain-type/complain-type-model';

    const { fields } = ComplainTypeModel;

    export default {
        name: 'ComplainTypeListTable',
        data() {
            return {
                presenter: ComplainTypeModel.presenter,
                actions: [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'delete',
                        icon: 'trash',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        customIcon: false,
                        type: 'primary'
                    }
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'complainType/list/rows',
                loading: 'shared/loading',
            }),
            fields() {
                return [
                    fields.name,
                    fields.status,
                ]
            },
            isLoading () {
                return this.loading['complainType/get'] ;
            },
        },
        methods: {
            ...mapActions({
                selectRecord: 'complainType/form/select',
                deleteRecord: 'complainType/form/remove',
            }),
            async onEdit(data) {
                await this.selectRecord(data.item);
                this.$emit('editCompainType', data);
            },
            async onDelete(data) {
                await this.deleteRecord(data.item.id);
                this.$emit('updateCompainType');
            },
        },
    };
</script>
