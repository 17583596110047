<template>
    <st-page
        :title="$t('COMPLAIN_TYPE.LIST.TITLE')"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <complain-type-list-toolbar @addComplainType="addComplainType"/>
        </template>
        <complain-type-list-filter ref="complainTypeListFilter">
            <complain-type-list-table
                @editCompainType="editCompainType"
                @updateCompainType="updateCompainType"
            />
        </complain-type-list-filter>

        <complain-type-form-modal
            ref="complainTypeFormModal"
            :edit-mode="editMode"
        ></complain-type-form-modal>
    </st-page>
</template>

<script>
    import ComplainTypeListFilter from '../components/ComplainTypeListFilter';
    import ComplainTypeListTable from '../components/ComplainTypeListTable';
    import ComplainTypeListToolbar from '../components/ComplainTypeListToolbar';
    import ComplainTypeFormModal from '@/modules/complain-type/components/ComplainTypeFormModal.vue';

    import { mapGetters } from "vuex";

    export default {
        name: 'ComplainTypeList',
        components: {
            ComplainTypeListFilter,
            ComplainTypeListTable,
            ComplainTypeListToolbar,
            ComplainTypeFormModal
        },
        data() {
            return {
                editMode: false,
            };
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
            }),
            isLoading() {
                return this.loading['complain/get'];
            },
        },
        methods: {
            addComplainType() {
                this.editMode = false;
                this.$refs.complainTypeFormModal.show();
            },
            editCompainType() {
                this.editMode = true;
                this.$refs.complainTypeFormModal.show();
            },
            updateCompainType() {
                this.$refs.complainTypeListFilter.refresh();
            }
        }
    }
</script>
