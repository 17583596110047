<template>
    <form class="form"
            novalidate="novalidate"
            id="st_modal_complain_type_form"
    >
        <div class="row pb-6">
            <div class="col">
                <div class="form-group">
                    <label>
                        {{ fields.name.label }} *
                    </label>
                    <st-input-text
                        ref="name"
                        name="name"
                        v-model="model[fields.name.name]"
                    />
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.status.label }} *
                    </label>
                    <b-form-select
                        v-model="model[fields.status.name]"
                        :options="fields.status.options"
                        ref="status"
                        name="status"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{
                                    $t('GENERAL.SELECT_PLACEHOLDER')
                                }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.complain_document_series_type_id.label }} *
                    </label>
                    <b-form-select
                        v-model=" model[fields.complain_document_series_type_id.name]"
                        :options="fields.complain_document_series_type_id.options"
                        :ref="fields.complain_document_series_type_id.name"
                        :name="fields.complain_document_series_type_id.name"
                    >
                        <template #first>
                            <b-form-select-option
                                :value="null"
                                disabled
                            >
                                {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.tenant_id.label }}
                    </label>
                    <b-form-select
                        v-model="model[fields.tenant_id.name]"
                        :options="fields.tenant_id.options"
                        :ref="fields.tenant_id.name"
                        :name="fields.tenant_id.name"
                    >
                        <template #first>
                            <b-form-select-option
                                :value="null"
                                disabled
                            >
                                {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <st-button
                size="large"
                variant="link"
                :callback="hide"
            > {{ $t('GENERAL.BUTTON.CANCEL') }}
            </st-button>
            <st-button
                size="large"
                variant="primary"
                :callback="doSubmit"
            >
                <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
            </st-button>
        </div>
    </form>
</template>

<script>

import {FormSchema} from '@/shared/form/form-schema';
import {createFormValidation} from '@/shared/utils/create-form-validation';
import {ComplainTypeModel} from '@/modules/complain-type/complain-type-model';

const {fields} = ComplainTypeModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.status,
    fields.complain_document_series_type_id,
    fields.tenant_id
]);

import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ComplainTypeForm',
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null
        };
    },
    computed: {
        ...mapGetters({
            record: 'complainType/form/record',
            filters: 'complainType/list/filters',
        }),
        fields() {
            return fields;
        }
    },
    created() {
        this.model = formSchema.initialValues(this.record);
    },
    mounted() {
        this.fv = createFormValidation('st_modal_complain_type_form', this.rules);
    },
    methods: {
        ...mapActions({
            doCreate: 'complainType/form/create',
            doUpdate: 'complainType/form/update',
            doFetch: 'complainType/list/doFetch',
        }),
        hide() {
            this.$emit('cancelForm');
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const {id, ...values} = formSchema.cast(this.model);
                values.complain_type_scope = 'tenant';
                if (this.editMode) {
                    this.doUpdate({id, values}).then(() => this.hide());
                } else {
                    this.doCreate(values).then(() => {
                        this.hide();
                        this.doFetch(this.filters);
                    });
                }
            }
        }
    },
};
</script>
