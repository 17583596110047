<template>
    <div>
        <st-modal
            id="st-modal-complain-type"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="headerText"
            customClass="form-modal"
            ref="complain-modal"
        >
            <template #body>
                <complain-type-form
                    ref="complain-type-form"
                    :editMode="editMode"
                    @cancelForm="hide"
                >
                </complain-type-form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ComplainTypeForm from './ComplainTypeForm.vue';
import {mapActions } from 'vuex';

export default {
    name: 'ComplainTypeFormModal',
    components: { ComplainTypeForm },
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headerText() {
            return this.editMode ?
                this.$t('COMPLAIN_TYPE.FORM.EDIT_TITLE') :
                this.$t('COMPLAIN_TYPE.FORM.ADD_TITLE');
        },
    },
    methods: {
        ...mapActions({
            unselectRecord: 'complainType/form/unselect',
        }),
        show() {
            this.$refs['complain-modal'].show();
        },
        hide() {
            if(this.editMode) this.unselectRecord();
            this.$refs['complain-modal'].hide();
        },
    },
};
</script>

